<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="안전보건관리계획서 개요" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn v-if="popupParam.vendorSafetyPlanId && editable && !data.vendorSafetyPlanStepCd" label="LBLREMOVE" icon="delete_forever" @btnClicked="removePlan" />
                <c-btn
                  v-show="popupParam.vendorSafetyPlanId && editable && !disabled"
                  :url="saveUrl"
                  :isSubmit="isSave2"
                  :param="data"
                  mappingType="PUT"
                  label="승인요청"
                  icon="check"
                  @beforeAction="savePlan2"
                  @btnCallback="saveCallback2" 
                />
                <c-btn
                  v-show="editable && !disabled"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="saveType"
                  label="LBLSAVE"
                  icon="save"
                  @beforeAction="savePlan"
                  @btnCallback="saveCallback" 
                />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-plant 
                  required
                  :editable="editable" 
                  :disabled="disabled"
                  type="edit" 
                  name="plantCd" 
                  v-model="data.plantCd" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-vendor
                  :editable="editable"
                  :disabled="disabled"
                  :selfFlag="false"
                  label="협력업체"
                  name="vendorCd"
                  v-model="data.vendorCd">
                </c-vendor>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                <c-text
                  :required="true"
                  :disabled="disabled"
                  :editable="editable"
                  label="공사명"
                  name="constructionName"
                  v-model="data.constructionName">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-datepicker
                  :required="true"
                  :range="true"
                  :disabled="disabled"
                  :editable="editable"
                  label="공사기간"
                  name="period"
                  v-model="data.period"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-field
                  :required="true"
                  :disabled="disabled"
                  :editable="editable"
                  :data="data"
                  :plantCd="data.plantCd"
                  deptValue="manageDeptCd"
                  type="dept_user"
                  userType="user"
                  label="현업담당자"
                  beforeText=""
                  name="manageUserId"
                  v-model="data.manageUserId" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" v-if="data.remarks">
                <c-text
                  :disabled="true"
                  :editable="editable"
                  label="반려사유"
                  name="remarks"
                  v-model="data.remarks">
                </c-text>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="안전보건관리계획서" class="cardClassDetailForm" :collapsed="true">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-upload 
                  label="샘플양식 다운로드"
                  :attachInfo="attachInfoSample"
                  :editable="false">
                </c-upload>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                <c-upload 
                  label="안전보건관리계획서 첨부"
                  :attachInfo="attachInfo"
                  :editable="editable && !disabled">
                </c-upload>
              </div>
            </template>
          </c-card>
        </div>
      </div>
    </q-form>
  </div>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'safety-plan-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        vendorSafetyPlanId: '',
      }),
    },
  },
  data() {
    return {
      data: {
        vendorSafetyPlanId: '',  // 안전작업계획서 일련번호
        vendorCd: '',  // 업체코드
        plantCd: '',  // 사업장코드
        constructionName: '',  // 공사명
        startDt: '',  // 공사시작일
        endDt: '',  // 공사종료일
        manageUserId: '',  // 담당자
        manageDeptCd: '',
        vendorSafetyPlanStepCd: '',  // 승인상태
        remarks: '',  // 반려사유
        period: [],
      },
      editable: true,
      detailUrl: '',
      saveUrl: '',
      saveType: 'POST',
      isSave: false,
      isSave2: false,
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'VENDOR_SAFETY_PLAN',
        taskKey: '',
      },
      attachInfoSample: {
        isSubmit: '',
        taskClassCd: 'VENDOR_SAFETY_PLAN_SAMPLE',
        taskKey: 'SAMPLE',
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    disabled() { // 승인요청 or 승인완료
      return this.data.vendorSafetyPlanStepCd == 'VSPSC00001' || this.data.vendorSafetyPlanStepCd == 'VSPSC00002';
    }
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.saveUrl = transactionConfig.sai.vendor.safety.plan.insert.url;
      this.detailUrl = selectConfig.sai.vendor.safety.plan.get.url;
      this.insertUrl = transactionConfig.sai.vendor.safety.plan.insert.url;
      this.updateUrl = transactionConfig.sai.vendor.safety.plan.update.url;
      this.deleteUrl = transactionConfig.sai.vendor.safety.plan.delete.url;
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.vendorSafetyPlanId) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.vendorSafetyPlanId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.data = _result.data;
          this.$set(this.attachInfo, 'taskKey', this.popupParam.vendorSafetyPlanId)
        },);
      } else {
        this.data.vendorCd = this.$store.getters.user.userId;
      }
    },
    savePlan() {
      if (this.popupParam.vendorSafetyPlanId) {
        this.saveUrl = this.updateUrl;
        this.saveType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.saveType = 'POST';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) { 
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.data.regUserId = this.$store.getters.user.userId
              this.data.chgUserId = this.$store.getters.user.userId

              this.isSave = !this.isSave
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(_result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$set(this.attachInfo, 'taskKey', _result.data)
      this.$set(this.attachInfo, 'isSubmit', uid())
      if (!this.popupParam.vendorSafetyPlanId) {
        this.popupParam.vendorSafetyPlanId = _result.data
      }
      this.getDetail();
    },
    savePlan2() {
      this.saveUrl = this.updateUrl;
      this.$refs['editForm'].validate().then(_result => {
        if (_result) { 
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: '현업담당자에게 승인요청을 하시겠습니까?', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.data.regUserId = this.$store.getters.user.userId
              this.data.chgUserId = this.$store.getters.user.userId
              this.data.vendorSafetyPlanStepCd = 'VSPSC00001'

              this.isSave2 = !this.isSave2
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback2(_result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$set(this.attachInfo, 'taskKey', _result.data)
      this.$set(this.attachInfo, 'isSubmit', uid())
      if (!this.popupParam.vendorSafetyPlanId) {
        this.popupParam.vendorSafetyPlanId = _result.data
      }
      this.getDetail();
    },
    removePlan() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.vendorSafetyPlanId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup')
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
  }
};
</script>
